<template>
  <v-card flat tile class="border-light-grey mt-4">
    <v-card-title class="headline grey lighten-4">
      <v-layout>
        <v-flex class="d-flex justify-content-between">
          <h3 class="custom-sub-header-blue-text">{{ pageTitle() }}</h3>
          
        </v-flex>
      
    <v-btn :loading="pageLoading" class="white--text mr-2" depressed
              color="blue darken-4" tile v-on:click="updateOrCreate">
              Save 
            </v-btn>
            <v-btn
              class="ml-2"
              style="font-weight: 700; color: black"
              :disabled="pageLoading"
              depressed
              tile
              v-on:click="goBack()"
            >
              Back
            </v-btn>
         
      </v-layout>
    </v-card-title>
    <p  style="color: #24326d; font-weight: 500;margin-bottom: 0px!important;">
      Please fill in the information below. The field labels marked with
      <span style="color: #e54643; font-weight: 600; font-size: 17px">*</span> are required input
      fields.
    </p>
    <v-row>
      <v-col class="md-10">
        <v-form ref="customerForm">

            <div class=" ml-3">
              <v-row>
            <v-col md="3"  >


<h3 class="custom-header-blue-text mr-2 text-truncate me-3 ">Total: {{formatMoney(totalValue)}}</h3>
            </v-col>
            <v-col md="3" >
              
            </v-col>
            <v-col md="3" >
              
            </v-col>
            <v-col md="3" >
              <DatePicker
                    :disabled="pageLoading"
                    :rules="[vrules.required(sale.date, 'Supplier')]" :class="{ required: !sale.date }"
                    :loading="pageLoading"
                    hide-details
                    v-model="sale.date"
                    placeholder="Date"
                  >
                  </DatePicker>
            </v-col></v-row>

              <v-expansion-panels  v-model="panel" multiple readonly >
                <v-expansion-panel >
                  <v-expansion-panel-header style="background-color:  #F5F5F5;" expand-icon="" >
                    <label class="red--text pl-3 ms-3"
                      style="font-size: 12px;font-weight: 600;font-size: 20px">CASH & SALES</label>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content >
                    <v-layout class="mx-4 align-layout">

                      <v-col md="2" class="my-auto ">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">CASH SALE</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Cash Sale"
                          v-model="sale.cash_sale">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">VISA CARD</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Visa"
                          v-model="sale.visa_card">
                        </QuantityInput>
                      </v-col>

                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label"><span style="font-weight: bold">DEBIT CARD</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details value="0"
                          placeholder="My Debit" v-model="sale.debit_card">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto py-0">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">MASTER CARD</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details v-model="sale.master_card"
                          placeholder="MasterCard">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">ONLINE BANKING</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details value="0"
                          v-model="sale.online_banking" placeholder="Online Banking">
                        </QuantityInput>
                      </v-col>


                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="cepfli" v-model="panels" multiple readonly>
                <v-expansion-panel >
                  <v-expansion-panel-header style="background-color:  #F5F5F5;"  expand-icon="">
                    <label class="red--text pl-3 ms-3"
                      style="font-size: 12px;font-weight: 600;font-size: 20px">ONLINE WALLET PAYMENT</label>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content >
                    <v-layout class="mx-4 align-layout">

                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">TOUCH & GO</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Cash Sale"
                          v-model="sale.touch">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">QR PAY</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Visa"
                          v-model="sale.qr_pay">
                        </QuantityInput>
                      </v-col>

                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label"><span style="font-weight: bold">GRAB PAY</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details value="0"
                          placeholder="Grab Pay" v-model="sale.grab_pay">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto py-0">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">BOOST</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details v-model="sale.boost"
                          placeholder="Boost">
                        </QuantityInput>
                      </v-col>



                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="cepfli" v-model="delivery" multiple readonly>
                <v-expansion-panel >
                  <v-expansion-panel-header style="background-color: #F5F5F5;"  expand-icon="">
                    <label class="red--text pl-3 ms-3"
                      style="font-size: 12px;font-weight: 600;font-size: 20px">FOOD PORTAL</label>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout class="mx-4 align-layout">

                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">GRAB FOOD</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Grab Food"
                          v-model="sale.grab_food">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">FOOD PANDA</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Food Panda"
                          v-model="sale.food_panda">
                        </QuantityInput>
                      </v-col>

                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="cepfli" v-model="voucher" multiple readonly>
                <v-expansion-panel >
                  <v-expansion-panel-header style="background-color: #F5F5F5;"  expand-icon="">
                    <label class="red--text pl-3 ms-3"
                      style="font-size: 12px;font-weight: 600;font-size: 20px">VOUCHER</label>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout class="mx-4 align-layout">

                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">FOC SALES</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Foc Sale"
                          v-model="sale.foc">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">VOUCHER CLAIM</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Voucher Claim"
                          v-model="sale.voucher_claim">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto">
                        <label for="title" class="btx-label">
                          <span style="font-weight: bold">VOUCHER SOLD</span>
                        </label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Voucher Sold"
                          v-model="sale.voucher_sold">
                        </QuantityInput>
                      </v-col>
                      <v-col md="2" class="my-auto"><label for="title" class="btx-label"><span
                            style="font-weight: bold">VOUCHER AWARD</span></label>
                        <QuantityInput :disabled="pageLoading" :loading="pageLoading" hide-details placeholder="Voucher Award"
                          v-model="sale.voucher_award">
                        </QuantityInput>
                      </v-col>

                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>


            <!-- <v-row>
              
              <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span style="color: #e54643; font-weight: bold">Date *</span></label
                  >
                  <DatePicker
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Date"
                  >
                  </DatePicker>
                </v-col>
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">CASH SALE</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Cash Sale"
                    v-model="sale.cashSale"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto"
                  ><label for="title" class="btx-label"
                    ><span style="font-weight: bold">VISA CARD</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Visa"
                    v-model="sale.visa"

                  >
                  </TextInput>
                </v-col>
              </v-layout>
              <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span style="font-weight: bold">DEBIT CARD</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    placeholder="My Debit"
                    v-model="sale.debit"

                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto py-0">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">MASTER CARD</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    v-model="sale.masterCard"

                    placeholder="MasterCard"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto"
                  ><label for="title" class="btx-label"
                    ><span style="font-weight: bold">ONLINE BANKING</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    v-model="sale.online"

                    placeholder="Online Banking"
                  >
                  </TextInput>
                </v-col>
              </v-layout>
              <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span  style="font-weight: bold">GRAB FOOD </span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    v-model="sale.grab_food"

                    placeholder="Grab Food"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">QR PAY </span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Qr Pay"
                    v-model="sale.qr"

                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto"
                  ><label for="title" class="btx-label"
                    ><span style="font-weight: bold">TOUCH & GO</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Touch & Go"
                    v-model="sale.touch"

                  >
                  </TextInput>
                </v-col>
              </v-layout>
              <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span style="font-weight: bold">GRAB PAY</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    v-model="sale.grab_pay"

                    placeholder="Grab Pay"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto py-0">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">FOODPANDA</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="FoodPanda"
                    v-model="sale.foodpanda"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto"
                  ><label for="title" class="btx-label"
                    ><span style="font-weight: bold">BOOST</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    v-model="sale.boost"

                    placeholder="Boost"
                  >
                  </TextInput>
                </v-col>
              </v-layout>
               <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span style="color: #e54643; font-weight: bold">TOTAL SALES </span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Total Sales"
                    v-model="sale.total"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">FOC SALES </span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Foc Sale"
                    v-model="sale.foc"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto"
                  ><label for="title" class="btx-label"
                    ><span style="font-weight: bold">VOUCHER CLAIM</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Voucher Claim"
                    v-model="sale.voucher_claim"

                  >
                  </TextInput>
                </v-col>
              </v-layout>
              <v-layout class="mx-4">
                <v-col md="4" class="my-auto">
                  <label for="title" class="btx-label"
                    ><span style="font-weight: bold">VOUCHER SOLD</span></label
                  >
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    value="0"
                    v-model="sale.voucher_sold"

                    placeholder="Voucher Sold"
                  >
                  </TextInput>
                </v-col>
                <v-col md="4" class="my-auto py-0">
                  <label for="title" class="btx-label">
                    <span style="font-weight: bold">VOUCHER AWARD</span>
                  </label>
                  <TextInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    hide-details
                    placeholder="Voucher Award"
                    v-model="sale.voucher_award"
                  >
                  </TextInput>
                </v-col>
                
              </v-layout>
             
             
             

              <v-col md="12" class="my-auto py-0">
                <v-btn color="blue darken-4" depressed tile class="ml-2 white--text">Submit</v-btn>
                <v-btn color="red darken-4" depressed tile class="ml-2 white--text">Reset</v-btn>
              </v-col>
            </v-row> -->
          <!-- </v-container> -->
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

// import TextInput from "@/view/components/TextInput.vue";
import DatePicker from "@/view/components/DatePicker";
import { CREATE_SALES, UPDATE_SALES } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { toSafeInteger } from "lodash";
import { QUERY } from "@/core/services/store/request.module";
import PosMixin from "@/core/mixins/pos.mixin";

import QuantityInput from "@/view/components/QuantityInput";
export default {
  mixins: [PosMixin],

  data() {
    return {
      sale: {
        date:null,
        cash_sale: 0,
        master_card: 0,
        debit_card: 0,
        visa_card: 0,
        online_banking: 0,
        touch: 0,
        grab_food: 0,
        qr_pay:0,
        grab_pay:0,
        boost:0,
        food_panda:0,
        foc: 0,
        voucher_claim: 0,
        voucher_sold:0,
        voucher_award:0,
        selectedFile: null,
      },
      total:0,

      panel: [0, 1],
      panels: [0, 1],
      delivery: [0, 1],
      voucher: [0, 1]


    };
  },
  components: {
    QuantityInput,
    DatePicker,
    // SelectInput,
  },
  methods: {
    getSaleData(id) {
      this.$store
        .dispatch(QUERY, {
          url: `find-sale/${id}`,
        })
        .then((data) => {
          this.sale=data.rows

          console.log(data, "barcodess");

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
      console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    async updateOrCreate() {
      const _this = this;
      this.sales = this.sale;
      console.log(this.total, "sff");

      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          console.log(this.product);
          const customer = await UPDATE_SALES(_this.customerId, _this.sale);
          console.log(customer)

          _this.$router.replace({
            name: "salessummary",
        
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Sale Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_SALES(_this.sales);
          console.log(customer)
          _this.$router.replace({
            name: "salessummary",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Sale Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    pageTitle() {
      if (this.customerId) {
        return "Update Sales Summary";
      }
      return "Create Sales Summary";
    },
    browseFile() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
    },
    uploadFile() {
      if (!this.selectedFile) {
        return;
      }
    },
  },
  computed: {
    totalValue() {
      const total  =
        Number(this.sale.cash_sale)+
        Number(this.sale.visa_card) + Number(this.sale.debit_card)+ Number(this.sale.master_card)+ Number(this.sale.online_banking)
        + Number(this.sale.touch)+ Number(this.sale.qr_pay)+ Number(this.sale.grab_pay)+ Number(this.sale.boost)+ Number(this.sale.grab_food)
        + Number(this.sale.food_panda)
        console.log(total,'total')

        return total;
    },
    selectedFileName() {
      return this.selectedFile ? this.selectedFile.name : "";
    },
  },
  mounted() {

    // if (this.$refs.supplierForm.validate()) {
    //     return false;
    //   }
    if(this.$route.params.id){
      const { params } = this.$route;
   
   const { id } = params;
   if (id) {
     this.customerId = toSafeInteger(id);
     this.getSaleData(this.customerId);
   } else {
     this.goBack();
   }
    }
    
    
  },
};
</script>

<style>
.file {
  visibility: hidden;
  position: absolute;
}

.custom-sub-header-blue-text {
  font-weight: 900 !important;
  color: #24326d !important;
  letter-spacing: 1.0px !important;
  line-height: 1.1em !important;
  text-transform: uppercase !important;
}
.col-md-3.col {
    max-height: 70px;
}
/* .align-layout{
  display: flex;
  justify-content: center
} */
</style>
